import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      locale: 'main/locale',
    }),
  },
  methods: {
    getFormattedPrice(val) {
      const value = val || 0

      const valueFloat = parseFloat(value)

      if (Number.isNaN(valueFloat)) {
        return value
      }

      return valueFloat.toFixed(2)
    },
    getFormattedDate(timestamp) {
      const date = new Date(timestamp * 1000)

      const formatter = new Intl.DateTimeFormat(
        this.locale,
        {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        },
      )

      return formatter.format(date)
    },
  },
}
